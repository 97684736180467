body {
  color: white;
  font-family: Maximum Impact;
}

::-webkit-scrollbar {
  background: transparent;
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0,0,0,0.61);
  border-radius: 10px;
}
