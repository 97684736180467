.icon {
  position: absolute;
  margin-left: 15px;
  margin-top: 7px;
}

.input {
  border-radius: 30px;
  background-color: #d9d9d96e;
  height: 30px;
  width: 100%;
  color: white;
  font-weight: 100;
  font-size: 20px;
  border-color: transparent;
  padding: 18px 18px 18px 50px;
}

input::placeholder {
  color: rgb(194, 194, 194);
}

input:focus {
  outline-width: 0;
  border: solid 1px white;
}
