.title {
  font-family: Roboto;
  font-weight: 600;
  font-size: 20px;
}

.list {
  display: flex;
  width: 100vw - 25px;
  overflow-x: hidden;
  padding-bottom: 30px;
}

.list:hover {
  overflow: overlay;
}

.tv_show_item {
  margin-right: 20px;
}
